<template>
  <div>
    <v-container fluid>
      <v-row v-if="workOrder">
        <v-col cols="12">
          <v-sheet outlined>
            <v-card-title class="text-h5">
              <v-btn
                icon
                fab
                outlined
                color="primary"
                x-small
                link
                active-class="false"
                class="mr-4"
                :to="{
                  name: 'aeronef',
                  params: { immatriculation: workOrder.aircraft.immatriculation, page: 'ot' },
                }"
              >
                <v-icon  class="primary--text"
                  >mdi-arrow-left-thick</v-icon
                >
              </v-btn>

              <strong>ORDRE TRAVAIL | </strong> <span class="ml-3">
              {{ workOrder.aircraft.immatriculation }}</span>
              <v-spacer></v-spacer>
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-btn small fab icon outlined v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      link
                      v-if="workOrder.status == 'new'"
                      :to="{
                        name: 'edit_ot',
                        params: { id: workOrder.id },
                      }"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-wrench-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Modifier</v-list-item-title>
                    </v-list-item >
                    <v-list-item v-if="workOrder.status == 'new'" @click="generateOT()">
                      <v-list-item-icon>
                        <v-icon>mdi-autorenew</v-icon>
                      </v-list-item-icon>

                      <v-list-item-title>Générer l'OT</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      v-if="workOrder.status == 'new'"
                      :to="{
                        name: 'retour_ot',
                        params: { id: workOrder.id },
                      }"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-file-upload-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-title>Retour d'OT</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      v-if="workOrder.status == 'new'"
                      @click="closeOt()"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-lock-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>cloturer</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      link
                      v-if="
                        $permissions.can(me, 'admin') &&
                        workOrder.status == 'close'
                      "
                      @click="uncloseOt()"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-lock-open-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Déclôturer</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="workOrder.status == 'new'" @click="deleteOt()">
                      <v-list-item-icon>
                        <v-icon color="red">mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="red--text"
                        >supprimer</v-list-item-title
                      >
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-card-title>

            <v-col cols="12">
              <v-row>
                <v-col cols="3">
                  <v-card-title>
                    <v-row>
                      <v-col class="text-center">INFORMATIONS</v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-col cols="1">
                          <v-icon>mdi-folder-pound-outline</v-icon>
                        </v-col>
                        <v-col cols="11" class="text-center">
                          <span class="font-weight-bold red--text">
                            {{ workOrder.reference }}
                          </span>
                        </v-col>

                        <v-col cols="1">
                          <v-icon>mdi-file-clock-outline</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-8 text-center">
                          <span v-if="workOrder.aprsDate">
                            <v-chip
                              class="ma-2"
                              color="pink lighten-2"
                              label
                              small
                              text-color="white"
                            >
                              <v-icon small left>mdi-archive-outline</v-icon>
                              Cloturé le
                              {{ workOrder.aprsDate | moment("DD/MM/YYYY") }}
                            </v-chip>
                          </span>
                          <span v-if="workOrder.ot && !workOrder.aprsDate">
                            <v-chip
                              class="ma-2"
                              color="orange lighten-2"
                              label
                              small
                              text-color="white"
                            >
                              <v-icon small left>mdi-file-edit</v-icon>
                              En attente de retour
                            </v-chip>
                          </span>
                          <span
                            v-if="
                              !workOrder.ot && workOrder.orderType != 'facture'
                            "
                          >
                            <v-chip
                              class="ma-2"
                              color="blue lighten-2"
                              label
                              small
                              text-color="white"
                            >
                              <v-icon small left
                                >mdi-file-document-edit-outline</v-icon
                              >
                              En cours d'élaboration
                            </v-chip>
                          </span>
                        </v-col>

                        <v-col cols="1">
                          <v-icon>mdi-calendar</v-icon>
                        </v-col>
                        <v-col cols="11" class="text-center">
                          <span>
                            Fait le {{ workOrder.createdAt | moment("DD/MM/YYYY") }}
                          </span>
                        </v-col>

                        <v-col cols="1">
                          <v-icon>mdi-account-edit-outline</v-icon>
                        </v-col>
                        <v-col cols="11" class="text-center">
                          <span>
                           crée par {{ workOrder.user.name }}
                          </span>
                        </v-col>

                        <!--  <v-col cols="1">
                          <v-icon>mdi-file-pdf-box</v-icon>
                        </v-col>
                        <v-col cols="4">Ordre de travail</v-col>
                        <v-dialog
                          v-if="
                            workOrder.orderType ==! 'facture' &&
                            workOrder.aprsDate == null
                          "
                          v-model="dialog_upload"
                          persistent
                          hide-overlay
                          max-width="600"
                        >
                           <v-col cols="2">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="primary--text"
                                dark
                                outlined
                                v-on="on"
                                @click="dialog_upload = !dialog_upload"
                              >
                                Uploader l'OT signé
                                <v-icon right dark> mdi-cloud-upload </v-icon>
                              </v-btn>
                            </template>
                          </v-col>

                          <v-card>
                            <v-card-title class="headline"
                              >Ajout de l'OT signé</v-card-title
                            >
                            <v-card-text class="pt-4">
                              <v-container>
                                <v-row>
                                  <v-col cols="12">
                                    <v-menu
                                      v-model="menu"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          v-model="datePicker"
                                          label="Date de signature"
                                          prepend-icon="mdi-calendar-month"
                                          outlined
                                          dense
                                          readonly
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="aprsDate"
                                        :first-day-of-week="1"
                                        locale="fr"
                                        color="green"
                                        no-title
                                        @input="menu = false"
                                      >
                                      </v-date-picker>
                                    </v-menu>
                                    <dashboard :uppy="uppy" />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                class="mr-4 primary--text"
                                outlined
                                depressed
                                @click="cleanDialog()"
                              >
                                Fermer</v-btn
                              >
                              <v-btn
                                class="mr-4 primary"
                                dark
                                @click="uploadSignedOT()"
                                >Uploader</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-col cols="7" class="text-center">
                          <template v-if="workOrder.signedWorkOrder">
                            <v-btn
                              dark
                              outlined
                              color="red"
                              target="_blank"
                              :href="workOrder.signedWorkOrder"
                            >
                              <v-icon>mdi-file-pdf-box</v-icon> Retour OT
                            </v-btn>
                          </template>
                          <v-btn disabled v-else
                            >En attente de retour
                          </v-btn>
                        </v-col>-->
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="3">
                  <v-sheet>
                    <v-card-title>
                      <v-row>
                        <v-col class="text-center">RESUME DE L'OT </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col cols="12">
                        <v-card-text>
                          <v-list>
                            <v-list-item>
                              <v-list-item-icon>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on">mdi-file-document-edit</v-icon>
                                    </template>
                                    <span>Type de visite</span>
                                  </v-tooltip>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-subtitle class="text-uppercase font-weight-bold"
                                  >{{ workOrder.orderType }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                  <span class="font-weight-bold"
                                    >{{ workOrder.subOrderType }}
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-icon v-bind="attrs" v-on="on">mdi-tools</v-icon>
                                  </template>
                                  <span>Atelier/meca</span>
                                </v-tooltip>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-subtitle>
                                  <span
                                    class="font-weight-bold primary--text"
                                    v-if="workOrder.workshop"
                                    >{{ workOrder.workshop.name }}
                                  </span>
                                  <span class="nonrens" v-else>
                                    Atelier non renseigné</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-icon v-bind="attrs" v-on="on">mdi-file-document-edit</v-icon>
                                  </template>
                                  <span>Aeronef</span>
                                </v-tooltip>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-subtitle>
                                  <span class="font-weight-bold primary--text"
                                    >{{
                                      workOrder.aircraft.cell.partModel.tcHolder
                                        .name
                                    }}
                                    {{
                                      workOrder.aircraft.cell.partModel
                                        .reference
                                    }}
                                    (s/n:
                                    {{ workOrder.aircraft.cell.serialNumber }})
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-icon v-bind="attrs" v-on="on">mdi-file-document-edit</v-icon>
                                  </template>
                                  <span>Facturation</span>
                                </v-tooltip>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-subtitle>
                                  <span
                                    class="font-weight-bold primary--text"
                                    v-if="workOrder.generateInvoice == true"
                                    >OT facturé
                                  </span>
                                  <span v-else class="font-weight-bold"
                                    >OT Gratuit
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                          </v-list>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
                <v-col cols="3">
                  <v-sheet>
                    <v-card-title>
                      <v-row>
                        <v-col class="text-center">ENVOI </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col cols="12">
                        <v-card-text>
                          <v-list>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-account</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="text-uppercase"
                                  >Personnel APRS
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <!-- <span class="font-weight-bold primary--text" 
                                    >
                                  </span> -->
                                  <span class="nonrens">
                                    Donnée non renseigné</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-calendar</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Date d'envoi
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span
                                    class="font-weight-bold primary--text"
                                    v-if="workOrder.aprsDate"
                                    >
                                  </span>
                                  <span class="nonrens" v-else>
                                    Donnée non renseigné</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>

                          <v-list>
                            <v-list-item v-if="workOrder.otAttachment">
                              <v-list-item-content>
                                <v-btn
                                  outlined
                                  color="red"
                                  target="_blank"
                                  :href="workOrder.otAttachment.fileUrl"
                                >
                                  <v-icon dark color="red" class="mr-2">mdi-file-pdf-box</v-icon>
                                  {{ workOrder.reference }}
                                </v-btn>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-else>
                              <v-list-item-content>
                                <v-btn color="grey" outlined>
                                  <v-icon dark class="mr-2"
                                    >mdi-file-pdf-box</v-icon
                                  >{{ workOrder.reference }}
                                </v-btn>
                             </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
                <v-col cols="3">
                  <v-sheet>
                    <v-card-title>
                      <v-row>
                        <v-col class="text-center">RETOUR </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col cols="12">
                        <v-card-text>
                          <v-list>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-account</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="text-uppercase"
                                  >Personnel APRS
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <!-- <span class="font-weight-bold primary--text" 
                                    >
                                  </span> -->
                                  <span class="nonrens">
                                    Donnée non renseigné</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-calendar</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Date d'APRS
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span
                                    class="font-weight-bold primary--text"
                                    v-if="workOrder.aprsDate"
                                    >{{
                                      workOrder.aprsDate | moment("DD/MM/YYYY")
                                    }}
                                  </span>
                                  <span class="nonrens" v-else>
                                    Donnée non renseigné</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon class="mr-7">
                                <v-btn
                                  dark
                                  outlined
                                  color="red"
                                  target="_blank"
                                  :href="workOrder.signedWorkOrderAttachment.fileUrl"
                                  v-if="workOrder.signedWorkOrderAttachment"
                                >
                                  <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                                <v-btn color="grey">
                                  <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                              </v-list-item-icon>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="6000"
      rounded="pill"
      color="red"
      top
      app
      text
    >
      <span class="text-center">{{ snackbarText }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>
    <confirm ref="confirm"></confirm>
  </div>
</template>

<style scoped>

.nonrens {
  font-style: italic;
  color: red;
}
</style>

<script>
import { workOrderQuery } from "../../graphql/work_order/work_order";
import { generateOtMutation } from "../../graphql/work_order/generate_ot";
import { updateWorkOrderMutation } from "../../graphql/work_order/update_work_order";
import { closeWorkOrderMutation } from "../../graphql/work_order/close_work_order";
import { deleteWorkOrderMutation } from "../../graphql/work_order/delete_work_order";
import { meQuery } from "@/graphql/me";
// import { Dashboard } from "@uppy/vue";
import ActiveStorageUpload from "uppy-activestorage-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import French from "@uppy/locales/lib/fr_FR";
import Uppy from "@uppy/core";
import confirm from "@/components/confirm.vue";

export default {
  name: "Pe",
  components: {
    // Dashboard,
    confirm,
  },
  data() {
    return {
      workOrder: null,
      // search: "",
      dialog_upload: false,
      // modal: false,
      menu: false,
      aprsDate: null,
      // isLoading: true,
      snackbar: false,
      snackbarText: "",
      uploadedFileID: null,
    };
  },
  methods: {
    cleanDialog() {
      this.dialog_upload = false;
    },
    generateOT() {
      this.$apollo
        .mutate({
          mutation: generateOtMutation,
          variables: {
            input: {
              id: this.workOrder.id,
            },
          },
        })
        .then(() => {
          this.$apollo.queries.workOrder.refetch();
          this.snackbarText = "OT généré";
          this.snackbar = true;
        });
    },
    uploadSignedOT() {
      this.$apollo
        .mutate({
          mutation: updateWorkOrderMutation,
          variables: {
            input: {
              id: parseInt(this.workOrder.id, 10),
              attributes: {
                aprsDate: this.aprsDate,
                signedWorkOrder: this.uploadedFileID,
              },
            },
          },
        })
        .then(() => {
          this.$apollo.queries.workOrder.refetch();
          this.cleanDialog();
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    closeOt() {
      this.$apollo
        .mutate({
          mutation: closeWorkOrderMutation,
          variables: {
            input: {
              id: parseInt(this.workOrder.id, 10),              
            },
          },
        })
        .then(() => {
          this.$apollo.queries.workOrder.refetch();
        });
    },
    uncloseOt() {
      this.$apollo
        .mutate({
          mutation: updateWorkOrderMutation,
          variables: {
            input: {
              id: parseInt(this.workOrder.id, 10),
              attributes: {
                status: "new",
              },
            },
          },
        })
        .then(() => {
          this.$apollo.queries.workOrder.refetch();
        });
    },
     deleteOt() {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteWorkOrderMutation,
                variables: {
                  input: {
                    id: parseInt(this.workOrder.id, 10),
                  },
                },
              })
              .then(() => {
                this.$router.push({
                  name: "aeronef",
                  params: {
                    immatriculation: this.workOrder.aircraft.immatriculation,
                    page: "ot",
                  },
                });
              });
          }
        });
    },
  },
  computed: {
    datePicker() {
      return this.formatDate(this.aprsDate);
    },
    uppy: (self) =>
      new Uppy({
        logger: Uppy.debugLogger,
        autoProceed: true,
        allowMultipleUploads: false,
        inline: true,
        width: "300px",
        height: "300px",
        locale: French,
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          console.log(
            "Upload complete! We’ve uploaded these files:",
            result.successful
          );

          self.uploadedFileID = result.successful[0].response.signed_id;
        }),
  },
  apollo: {
    me: {
      query: meQuery,
    },
    workOrder: {
      query: workOrderQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
};
</script>