import gql from 'graphql-tag';

export const partModelQuery = gql`query PartModel($id: ID!) {
  partModel(id: $id) {
    id
    reference
    type
    typeRaw
    serie
    tcds
    motorType
    flyCategory
    fuelType
    mtow
    numberMotor
    designation
    designationEn
    pressurized
    fullName
    tcHolder {
      id
      name
    }
    partSerie {
      id
      reference
    }
    category {
      id
      name
    }
    ata {
      id
      chapter
      name
    }
    manuals {
      id
      number
      title
    }
  }
}`