import gql from 'graphql-tag';

export const nextCensToExpiredQuery = gql`
  query nextCensToExpired {
    nextCensToExpired {
      id
      number
      expiredAt
      manualExpiredAt
      aircraft {
        id
        immatriculation
        longMaintenance
      }
    }
  }`
