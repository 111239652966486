import gql from 'graphql-tag';

export const maintenanceProgrammesQuery = gql`
  query($status: String, , $year: String) {
    maintenanceProgrammes(status: $status, year: $year) {
      closedAt
      createdAt
      exportedAt
      id
      reference
      registrant
      status
      updatedAt
      programmeFile
      signedProgramme
      signedAt
      expiredAt
      manuals
      annexDData
      owners
      validatedAt
      aircraft {
        id
        immatriculation
        contact {
          id
          name
        }
        cell {
          id
          serialNumber
          partModel {
            id
            reference
            fullName
            tcHolder {
              id
              name
            }
          }
        }
      }
  }
}`
