<template>
  <div>
    <v-sheet outlined>
      <v-card-title>
        <span class="text-center primary--text"
          >HEURES EN ATTENTE DE VALIDATION</span
        >
      </v-card-title>
      <v-data-table
        :headers="headersval"
        :items="fliesToValidated"
        no-results-text="Aucune(s) heure(s) de vol en attente"
        no-data-text="Aucune(s) heure(s) de vol en attente"
        hide-default-footer
        dense
        locale="fr-FR"
      >
        <template v-slot:item.hdv="{ item }">
          {{ showHours(item) }}
          <!-- TODO : convertir fonction affichage heures en fonction global -->
        </template>
        <template v-slot:item.cycle="{ item }">
          {{ showCycle(item) }}
        </template>
        <template v-slot:item.flyDate="{ item }">
          {{ item.flyDate | moment("DD/MM/YYYY") }}
        </template>
        <template v-slot:item.hdvc="{ item }">
          {{ item.totalValue["hours"] }}
        </template>
        <template v-slot:item.action="{}">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon>
                <v-icon v-on="on" color="green">mdi-check-bold</v-icon>
              </v-btn>
            </template>
            <span>Accepter</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon>
                <v-icon v-on="on" color="red">mdi-close-thick</v-icon>
              </v-btn>
            </template>
            <span>Refuser</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-sheet>
    <v-divider></v-divider>
    <v-sheet outlined flex>
      <v-card-title>
        <v-dialog v-model="dialog" persistent max-width="400px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" class="btn"
    depressed
              >Ajouter des heures</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Ajout d'heure(s)</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="10" class="pb-0 pt-8">
                    <v-dialog
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Date"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          dense
                          readonly
                          outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="date"
                        :first-day-of-week="1"
                        locale="fr"
                        @input="menu = false"
                      >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6" class="py-0 pr-0">
                    <v-text-field
                      label="HH"
                      requis
                      dense
                      outlined
                      prepend-icon="mdi-clock-outline"
                      v-model="hours[aircraft.cell.id]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <span class="font-weight-bold pb-4" style="font-size: 20px"
                      >:</span
                    ></v-col
                  >
                  <v-col cols="3" class="py-0 pl-0">
                    <v-text-field
                      label="mm"
                      requis
                      dense
                      outlined
                      v-model="minutes[aircraft.cell.id]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="10" class="py-0">
                    <v-text-field
                      label="Cycles Totaux"
                      dense
                      outlined
                      prepend-icon="mdi-airplane-landing"
                      v-model="cycles[aircraft.cell.id]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions >
              <v-spacer></v-spacer>
              <v-btn
                outlined
                depressed
                class="primary--text mb-4"
                @click="clearForm()"
              >
                Fermer
              </v-btn>
              <v-btn class="mr-4 btn mb-4"  @click="createFly()" :loading="saveLoading">
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="flies"
        :items-per-page="10"
        no-data-text="Pas d'heures de vol"
        dense
        locale="fr-FR"
      >
        <template v-slot:item.hdv="{ item }">
          {{ showHours(item) | formatMinutes }}
          <!-- TODO : convertir fonction affichage heures en fonction global -->
        </template>
        <template v-slot:item.cycle="{ item }">
          {{ showCycle(item) }}
        </template>
        <template v-slot:item.flyDate="{ item }">
          {{ item.flyDate | moment("DD/MM/YYYY") }}
        </template>
        <template v-slot:item.hdvc="{ item }">
          {{ item.totalValue["hours"] }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon>
                <v-icon v-on="on">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Modifier</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="removeFly(item)">
                <v-icon v-on="on">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>Supprimer</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-sheet>
    <confirm ref="confirm"></confirm>
  </div>
</template>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
tbody td {
  white-space: nowrap;
}
</style>


<script>
import { fliesQuery } from "../../../graphql/fly/flies";
import { createFlyMutation } from "../../../graphql/fly/create_fly";
import { deleteFlyMutation } from "../../../graphql/fly/delete_fly";
import confirm from "@/components/confirm.vue";


export default {
  name: "dataTableHeureVol",
  components : {
    confirm,
  },
  props: {
    aircraft: {
      // type: Number,
      required: true,
    },
  },
  data: () => {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      dialog: false,
      search: "",
      cycles: [],
      hours: [],
      minutes: [],
      flies: [],
      fliesToValidated: [],
      saveLoading: false,
      headers: [
        { text: "DATE", value: "flyDate" },
        { text: "HEURE DE VOL", value: "hdv" },
        { text: "CYCLES/ATTERRISSAGE", value: "cycle" },
        { text: "HEURES TOTALES CELLULE", value: "hdvc" },
        { text: "", value: "action", width: "110px" },
      ],
      headersval: [
        { text: "DATE", value: "flyDate" },
        { text: "HEURE DE VOL", value: "hdv" },
        { text: "CYCLES/ATTERRISSAGE", value: "cycle" },
        { text: "HEURES TOTALES CELLULE", value: "hdvc" },
        { text: "", value: "action", width: "110px" },
      ],
      HeureVolval: [
        {
          flyDate: "01/06/2021",
          hdv: "3h15",
          hdvc: "2 021h22",
          cycle: "N/A",
        },
      ],
    };
  },
  computed: {
    datePicker() {
      return this.formatDate(this.date);
    },
    calcFlyValue() {
      var tab = [];

      var h = {
        aircraft_part_id: parseInt(this.aircraft.cell.id, 10),
        changes: [],
      };
      console.log(this.cycles);
      console.log(this.aircraft.cell.id);
      if (this.cycles[this.aircraft.cell.id]) {
        const hCycle = {
          unit_code: "cycles",
          value: parseInt(this.cycles[this.aircraft.cell.id], 10),
        };
        h["changes"].push(hCycle);
      }
      h["changes"].push({
        unit_code: "hours",
        value: this.totalHours(
          this.hours[this.aircraft.cell.id],
          this.minutes[this.aircraft.cell.id]
        ),
      });
      tab.push(h);

      console.log(tab);
      return tab;
    },
  },
  methods: {
    showCycle(fly) {
      var ret = "";
      fly.value.forEach((f) => {
        if (f["aircraft_part_id"] == this.aircraft.cell.id) {
          f["changes"].forEach((c) => {
            if (c.unit_code == "cycles") {
              ret = c.value;
            }
          });
        }
      });
      return ret;
    },
    showHours(fly) {
      var ret = "";
      fly.value.forEach((f) => {
        if (f["aircraft_part_id"] == this.aircraft.cell.id) {
          f["changes"].forEach((c) => {
            console.log(c);
            if (c.unit_code == "hours") {
              console.log(c.value);
              ret = c.value;
            }
          });
        }
      });
      return ret;
    },
    totalHours(hours, minutes) {
      if (!hours && !minutes) {
        return null;
      }
      var totalMinutes = 0;
      if (hours) {
        totalMinutes += parseInt(hours, 10) * 60;
      }
      if (minutes) {
        totalMinutes += parseInt(minutes, 10);
      }
      return totalMinutes;
    },
    clearForm() {
      this.date = null;
      this.hours = null;
      this.minutes = null;
      this.cycles = null;
      this.dialog = false;
    },
    createFly() {
      this.saveLoading = true;

      this.$apollo
        .mutate({
          mutation: createFlyMutation,
          variables: {
            input: {
              attributes: {
                aircraftId: parseInt(this.aircraft.id, 10),
                flyDate: this.date,
                validated: true,
                value: this.calcFlyValue,
              },
            },
          },
        })
        .then(() => {
          this.dialog = false;
          this.$apollo.queries.flies.refetch();
          this.$apollo.queries.fliesToValidated.refetch();
        });
    },
    removeFly(item) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteFlyMutation,
                variables: {
                  input: {
                    id: parseInt(item.id, 10),
                  },
                },
              })
              .then(() => {
                this.$apollo.queries.flies.refetch();
              });
          }
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  apollo: {
    flies: {
      query: fliesQuery,
      variables() {
        return {
          aircraftId: this.aircraft.id,
          validated: true,
        };
      },
    },
    fliesToValidated: {
      query: fliesQuery,
      variables() {
        return {
          aircraftId: this.aircraft.id,
          validated: false,
        };
      },
      update: (data) => data.flies,
    },
  },
};
</script>