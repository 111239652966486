import { render, staticRenderFns } from "./dataTablePiece.vue?vue&type=template&id=1f8d6deb&scoped=true&"
import script from "./dataTablePiece.vue?vue&type=script&lang=js&"
export * from "./dataTablePiece.vue?vue&type=script&lang=js&"
import style0 from "./dataTablePiece.vue?vue&type=style&index=0&id=1f8d6deb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f8d6deb",
  null
  
)

export default component.exports