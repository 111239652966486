import gql from 'graphql-tag';

export const createAircraftMutation = gql`
  mutation createAircraft($input: CreateAircraftInput!) {
    createAircraft(input: $input) {
      aircraft {
        id
        status
        immatriculation
        photo
        kardex
        contact {
            id
            name
        }
        supervisor {
          id
          name
        }
        workshop {
          id
          name
        }
        updatedAt
        createdAt
        cell {
            id
            serialNumber
            flyValue
            manufacturer {
              id
              name
              tcHolder {
                id
                name
              }
            }
            partModel {
              id
              reference
              manuals {
                id
                number
                title
              }
            }
          }
          motors {
            id
            serialNumber
            flyValue
            manufacturer {
              id
              name
              tcHolder {
                id
                name
              }
            }
            partModel {
              id
              reference
              manuals {
                id
                number
                title
              }
            }
          }
          propellers {
            id
            serialNumber
            flyValue
            manufacturer {
              id
              name
              tcHolder {
                id
                name
              }
            }
            partModel {
              id
              reference
              manuals {
                id
                number
                title
              }
            }
          }
        }
      }
    
}`
